// React Core
import React from 'react'

// React Router
import { Link } from 'react-router-dom'

// Material UI Components
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

// FontAwesome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeatherAlt } from '@fortawesome/free-solid-svg-icons'

// CSS Modules
import styles from './modules/Login.module.css'

export default class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            uname: null,
            passwd: null,
            error: false
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()

        if (this.state.uname && this.state.passwd) {
            this.props.signIn(this.state.uname, this.state.passwd, res => {
                if (res) this.setState({ error: false })
                else this.setState({ error: true })
            })
        } else {
            this.setState({ error: true })
        }
    }

    render() {
        return (
            <Container component={Paper} maxWidth='md' className={styles.Container}>
                <Container maxWidth='xs'>
                    <Typography variant='h1' className={styles.Logo} color='primary'>
                        <FontAwesomeIcon icon={faFeatherAlt} />
                    </Typography>
                    <Box component='form' onSubmit={this.handleSubmit}>
                        <TextField variant='standard' label="Kullanıcı Adı" fullWidth error={this.state.error} onChange={event => this.setState({ uname: event.target.value })} />
                        <TextField variant='standard' label="Parola" type='password' fullWidth error={this.state.error} onChange={event => this.setState({ passwd: event.target.value })} />
                        <ButtonGroup color='primary' variant='contained' fullWidth disableElevation>
                            <Button className={styles.Button} component={Link} to='/user'>Kimlik Oluştur</Button>
                            <Button type='submit' className={styles.Button}>Giriş Yap</Button>
                        </ButtonGroup>
                    </Box>
                </Container>
                <Backdrop className={styles.Backdrop} open={this.props.block}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            </Container>
        )
    }
}
