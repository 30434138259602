// Helper Functions
import { generateHash } from './Helper'

// React Core
import React from 'react'

// React Router
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

// Material UI Theme
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { trTR } from '@material-ui/core/locale'
import DefaultTheme from './theme/Default.json'

// Components
import User from './User.jsx'
import Login from './Login.jsx'
import Main from './Main.jsx'
import Profile from './Profile.jsx'

export default function App() {
    return (
        <ThemeProvider theme={createTheme(DefaultTheme, trTR)}>
            <AppRouter />
        </ThemeProvider>
    )
}

class AppRouter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hash: localStorage.getItem('hash'),
            loginBlock: false,
            mainBlock: false,
            profileBlock: false
        }
    }

    signIn = (uname, passwd, callback) => {
        this.setState({ loginBlock: true })

        fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ hash: generateHash(uname, passwd) })
        }).then(res => {
            if (res.ok) return res.json()
            throw Error('API Error: /login response was not OK!')
        }).then(data => {
            localStorage.setItem('hash', data.hash)
            localStorage.setItem('sync', data.sync)
            localStorage.setItem('birds', data.birds ? JSON.stringify(data.birds) : [])
            this.setState({ hash: data.hash })
            callback(true)
        }).catch(err => {
            console.error(err)
            callback(false)
        }).finally(() => {
            this.setState({ loginBlock: false })
        })
    }

    synchronize = (callback) => {
        this.setState({ mainBlock: true })

        fetch('/api/sync', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                hash: localStorage.getItem('hash'),
                sync: localStorage.getItem('sync'),
                birds: JSON.parse(localStorage.getItem('birds'))
            })
        }).then(res => {
            if (res.ok) return res.json()
            throw Error('API Error: /sync response was not OK!')
        }).then(data => {
            if (data.birds) localStorage.setItem('birds', JSON.stringify(data.birds))
            localStorage.setItem('sync', data.sync)
            callback(true)
        }).catch(err => {
            console.error(err)
            callback(false)
        }).finally(() => {
            this.setState({ mainBlock: false })
        })
    }

    changeImage = (content, image, callback) => {
        this.setState({ profileBlock: true })

        const data = new FormData()
        data.append('image', image)
        data.append('imageOld', content.img || 'undefined')

        fetch('/api/image', {
            method: 'POST',
            body: data
        }).then(res => {
            if (res.ok) return res.json()
            throw Error('API Error: /image response was not OK!')
        }).then(data => {
            const birds = JSON.parse(localStorage.getItem('birds'))
            const index = birds.findIndex(bird => bird.ring === content.ring)
            birds[index].img = data.img
            localStorage.setItem('birds', JSON.stringify(birds))
            localStorage.setItem('sync', (new Date()).toISOString())
            callback(true)
        }).catch(err => {
            console.error(err)
            callback(false)
        }).finally(() => {
            this.setState({ profileBlock: false })
        })
    }

    signOut = () => {
        this.synchronize(res => {
            if (res) {
                localStorage.clear()
                this.setState({hash: undefined})
            } else {
                alert('Senkronizasyon başarısız. Çıkış yapılamadı.')
            }
        })
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" render={() => (
                        <Redirect to="/login" />
                    )} />
                    <Route exact path="/user" render={() => (
                        this.state.hash ? (
                            <Redirect to="/main" />
                        ):(
                            <User />
                        )
                    )} />
                    <Route exact path="/login" render={() => (
                        this.state.hash ? (
                            <Redirect to="/main" />
                        ):(
                            <Login block={this.state.loginBlock} signIn={this.signIn} />
                        )
                    )} />
                    <Route exact path="/main" render={({ location, history }) => (
                        this.state.hash ? (
                            <Main block={this.state.mainBlock} location={location} history={history} signOut={this.signOut} synchronize={this.synchronize} />
                        ):(
                            <Redirect to="/login" />
                        )
                    )} />
                    <Route exact path="/profile" render={({ location, history }) => (
                        this.state.hash ? (
                            <Profile block={this.state.profileBlock} location={location} history={history} changeImage={this.changeImage} />
                        ):(
                            <Redirect to="/login" />
                        )
                    )} />
                </Switch>
            </Router>
        )
    }
}
