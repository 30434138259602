import Crypto from 'crypto'

export function compare(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false
    if (obj1 === null) return obj2 === null

    for (let prop in obj1) {
        if (obj1.hasOwnProperty(prop) !== obj2.hasOwnProperty(prop)) return false

        switch (typeof (obj1[prop])) {
            case 'object':
                if (!compare(obj1[prop], obj2[prop])) return false
                if (JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop])) return false
                break

            case 'function':
                if (typeof (obj2[prop]) === 'undefined' || (prop !== 'compare' && obj1[prop].toString() !== obj2[prop].toString())) return false
                break

            default:
                if (obj1[prop] !== obj2[prop]) return false
        }
    }

    for (let prop in obj2) if (typeof (obj1[prop]) === 'undefined') return false

    return true
}

export function generateHash(uname, passwd) {
    return Crypto.createHash('sha256').update(`${uname}+${passwd}`).digest('base64')
}

export function getProfileTemplete() {
    return JSON.parse(JSON.stringify({
        ring: "",
        male: false,
        status: {
            fav: false,
            sold: false,
            dead: false,
            own: true
        },
        father: {
            father: {},
            mother: {}
        },
        mother: {
            father: {},
            mother: {}
        }
    }))
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
    ctx.drawImage(image, 0, 0)

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    ctx.putImageData(data, 0, 0)

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            resolve(new File([blob], `upload_${(Math.random() * 1e8).toFixed(0)}.jpg`, { type: 'image/jpeg' }))
        }, 'image/jpeg')
    })
}
