// React Core
import React from 'react'

// CSS Modules
import styles from './modules/User.module.css'

// Material UI Components
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

export default function App() {
    return (
        <Container component={Paper} maxWidth='md' className={styles.Container}>
            <Typography variant='h4' color='primary'>Kullanıcı Bilgileri</Typography>
            <Box component='form' method='POST' action='/usr' className={styles.Form}>
                <TextField name='uname' label='Kullanıcı Adı' required />
                <TextField name='passwd' label='Parola' required />
                <Button type='submit' variant='contained' color='primary'>Gönder</Button>
            </Box>
        </Container>
    )
}
