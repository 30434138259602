// Helper functions
import { compare, getCroppedImg, getProfileTemplete } from './Helper'

// React
import React, { useEffect, useState } from 'react'
import Main from './Main.jsx'

// React Router
import { Link } from 'react-router-dom'

// Date Utils
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'
import trLocale from 'date-fns/locale/tr'

import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers'

// Material UI Components
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Fab from '@material-ui/core/Fab'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faRing,
    faMars,
    faVenus,
    faStar,
    faSkull,
    faDollarSign,
    faEgg,
    faCalendarAlt,
    faHome,
    faTrash,
    faPen,
    faTimes,
    faEdit,
    faSave
} from '@fortawesome/free-solid-svg-icons'

// Image Editor
import Cropper from 'react-easy-crop'

// CSS Modules
import styles from './modules/Profile.module.css'

// Main Component
export default class Profile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initial: getProfileTemplete(),
            profile: getProfileTemplete()
        }

        this.updateFunctions = {
            updateImage: (image) => {
                this.props.changeImage(this.state.initial, image, res => {
                    if (res) this.getProfile(this.state.initial.ring)
                    else alert('Resim değişikliği başarısız!')
                })
            },
            updateStatus: (update) => {
                const change  = JSON.parse(JSON.stringify(this.state.profile))
                change.status = {...update.status}
                change.male   = update.male
                this.setState({ profile: change })
            },
            updateDate: (date) => {
                const change = JSON.parse(JSON.stringify(this.state.profile))
                change.date  = date
                this.setState({ profile: change })
            },
            updateRing: (ring) => {
                const change = JSON.parse(JSON.stringify(this.state.profile))
                const old    = change.ring
                change.ring  = ring
                this.setState({ profile: change }, () => {
                    if (old === ring) {
                        alert('Numara değiştirilmeden kaydedilemez!')
                    } else {
                        this.updateFunctions.save(old, ring)
                        this.props.history.push('/profile?ring=' + ring)
                    }
                })
            },
            updateNotes: (notes) => {
                const change = JSON.parse(JSON.stringify(this.state.profile))
                change.notes = notes
                this.setState({ profile: change })
            },
            updateParent: (ring) => {
                const change = JSON.parse(JSON.stringify(this.state.profile))
                const birds  = JSON.parse(localStorage.getItem('birds'))
                const index  = birds.findIndex(bird => bird.ring === ring)
                change[birds[index].male ? 'father' : 'mother'] = JSON.parse(JSON.stringify(birds[index]))
                this.getParents(birds, change[birds[index].male ? 'father' : 'mother'])
                delete this.state.profile[birds[index].male ? 'father' : 'mother'].father.parents
                delete this.state.profile[birds[index].male ? 'father' : 'mother'].mother.parents
                this.setState({ profile: change })
            },
            delete: () => {
                const birds = JSON.parse(localStorage.getItem('birds'))
                const index = birds.findIndex(bird => bird.ring === this.state.initial.ring)
                birds.splice(index, 1)
                localStorage.setItem('birds', JSON.stringify(birds))
                localStorage.setItem('sync', (new Date()).toISOString())
                this.props.history.push('/main')
            },
            reset: () => {
                this.setState({ profile: JSON.parse(JSON.stringify(this.state.initial)) })
            },
            save: (_old, _new) => {
                const birds    = JSON.parse(localStorage.getItem('birds'))
                const indexNew = birds.findIndex(bird => bird.ring === _new)
                
                if (indexNew === -1) {
                    const indexOld = birds.findIndex(bird => bird.ring === _old)
                    const update   = JSON.parse(JSON.stringify(this.state.profile))
                    update.parents = [update.father.ring, update.mother.ring]
    
                    delete update.father
                    delete update.mother
    
                    if (indexOld === -1) {
                        birds.push(update)
                    } else {
                        birds[indexOld] = update
                    }
    
                    localStorage.setItem('birds', JSON.stringify(birds))
                    localStorage.setItem('sync', (new Date()).toISOString())
    
                    this.getProfile(_new || _old)
                } else {
                    alert(`Bilezik ${_new} başka bir kuşa ait!`)
                }
            }
        }
    }

    getProfile = (ring) => {
        const birds  = JSON.parse(localStorage.getItem('birds'))
        const update = JSON.parse(JSON.stringify(birds.filter(bird => bird.ring === ring)[0]))

        this.getParents(birds, update)
        this.getParents(birds, update.father)
        this.getParents(birds, update.mother)

        delete update.father.parents
        delete update.mother.parents

        this.setState({ initial: JSON.parse(JSON.stringify(update)) })
        this.setState({ profile: JSON.parse(JSON.stringify(update)) })
    }

    getParents = (birds, ref) => {
        ref.father = { male: true }
        ref.mother = { male: false }

        if (ref.parents) {
            ref.parents.forEach(ring => {
                if (ring !== null) {
                    const parent = birds.filter(bird => bird.ring === ring)[0]

                    if (parent) {
                        if (parent.male) {
                            ref.father.ring    = parent.ring
                            ref.father.img     = parent.img
                            ref.father.male    = parent.male
                            ref.father.parents = parent.parents
                        } else {
                            ref.mother.ring    = parent.ring
                            ref.mother.img     = parent.img
                            ref.mother.male    = parent.male
                            ref.mother.parents = parent.parents
                        }
                    }
                }
            })
        }

        delete ref.parents
    }

    componentDidMount() {
        const ring = (new URLSearchParams(this.props.location.search)).get('ring')
        
        if (!ring || ring === 'undefined') {
            alert('Kuş bulunamadı!')
            this.props.history.push('/main')
            window.location.reload()
        } else {
            this.getProfile(ring)
        }
    }

    componentDidUpdate() {
        const ring  = (new URLSearchParams(this.props.location.search)).get('ring')
        if (ring !== this.state.initial.ring) {
            if (!ring || ring === 'undefined') {
                alert('Kuş bulunamadı!')
                this.props.history.goBack()
            } else {
                this.getProfile(ring)
            }
        }
    }

    render() {
        return (
            <Container component={Paper} className={styles.Container} maxWidth='md' disableGutters>
                <ProfileAppBar
                    updated={compare(this.state.initial, this.state.profile)}
                    updateFunctions={this.updateFunctions}
                    content={this.state.profile}
                />
                <ProfileContent
                    content={this.state.profile}
                    updateFunctions={this.updateFunctions}
                />
                <Backdrop className={styles.Backdrop} open={this.props.block}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            </Container>
        )
    }
}

class ProfileAppBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            iconHeight: undefined,
            dialogOpen: false,
            dateOpen: false,
            ring: props.content.ring
        }

        this.icon = React.createRef()
        this.form = React.createRef()
    }

    openDateDialog = (open) => {
        this.setState({ dateOpen: open })
    }

    render() {
        return (
            <AppBar className={styles.AppBar} position='relative' elevation={0}>
                <ProfileRing ring={this.props.content.ring} updateRing={this.props.updateFunctions.updateRing} />
                <ProfileStatus male={this.props.content.male} status={this.props.content.status} updateStatus={this.props.updateFunctions.updateStatus} openDateDialog={this.openDateDialog} />
                <ProfileAppBarButtons save={() => this.props.updateFunctions.save(this.props.content.ring)} reset={this.props.updateFunctions.reset} updated={this.props.updated} />
                <Box className={styles.Shrink} display='none'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
                        <DatePicker
                            views={["year"]}
                            open={this.state.dateOpen}
                            onClose={() => this.setState({ dateOpen: false })}
                            value={new Date(this.props.content.date)}
                            minDate={new Date(2017, 0)}
                            maxDate={new Date((new Date()).getFullYear() + 1, 0)}
                            onChange={date => this.props.updateFunctions.updateDate(date)}
                            cancelLabel='Kapat'
                            okLabel='Kaydet'
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </AppBar>
        )
    }
}

class ProfileRing extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dialogOpen: false,
            ring: ""
        }

        this.formRef = React.createRef()
    }

    render() {
        return (
            <Box className={styles.Shrink}>
                <Typography variant='h5' className={styles.Ring}>
                    <FontAwesomeIcon icon={faRing} size='lg' />
                    &nbsp;
                    {this.props.ring}
                    &nbsp;
                    <IconButton size={window.innerWidth < 600 ? 'small' : 'medium'} color='inherit' onClick={() => this.setState({ dialogOpen: true })}>
                        <FontAwesomeIcon icon={faPen} size='xs' />
                    </IconButton>
                    <Dialog open={this.state.dialogOpen || false} TransitionProps={{ onEnter: () => this.setState({ ring: this.props.ring }) }} onClose={() => this.setState({ dialogOpen: false })}>
                        <DialogTitle>Bilezik Numarası Değişikliği</DialogTitle>
                        <DialogContent>
                            <Box component='form' ref={this.formRef} onSubmit={event => {
                                event.preventDefault()
                                this.props.updateRing(this.state.ring)
                                this.setState({ dialogOpen: false })
                            }}>
                                <TextField label='Bilezik' value={this.state.ring} onChange={event => this.setState({ ring: event.target.value })} fullWidth autoFocus required />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button color='primary' onClick={() => this.setState({ ring: this.props.ring, dialogOpen: false })}>
                                Kapat
                            </Button>
                            <Button color='primary' onClick={() => this.formRef.current.requestSubmit()}>
                                Kaydet
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Typography>
            </Box>
        )
    }
}

class ProfileStatus extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            iconHeight: 0,
            dateOpen: false
        }

        this.iconRef = React.createRef()
    }

    handleClick = (event) => {
        const update  = {
            male: this.props.male || false,
            status: {...this.props.status}
        }

        switch (event.currentTarget.name) {
            case 'gender': update.male        = !update.male       ; break;
            case 'fav'   : update.status.fav  = !update.status.fav ; break;
            case 'sold'  : update.status.sold = !update.status.sold; break;
            case 'dead'  : update.status.dead = !update.status.dead; break; 
            case 'own'   : update.status.own  = !update.status.own ; break;
            default: throw Error('Key is not valid')
        }

        this.props.updateStatus(update)
    }

    componentDidMount() {
        this.setState({ iconHeight: this.iconRef.current.offsetHeight })
    }

    render() {
        return (
            <Box className={styles.Shrink + ' ' + styles.Order_3} display='flex' justifyContent='center'>
                <IconButton name='gender' style={{ width: this.state.iconHeight, color: this.props.male ? 'skyblue' : 'pink', filter: 'drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.25))' }} ref={this.iconRef} onClick={event => this.handleClick(event)}>
                    <FontAwesomeIcon icon={this.props.male ? faMars : faVenus} />
                </IconButton>
                <IconButton name='fav' style={{ width: this.state.iconHeight, color: this.props.status.fav ? 'yellow' : 'rgba(0, 0, 0, .15)', filter: this.props.status.fav ? 'drop-shadow(2px 2px 1px rgba(0, 0, 0, .25))' : 'unset' }} onClick={event => this.handleClick(event)}>
                    <FontAwesomeIcon icon={faStar} />
                </IconButton>
                <IconButton name='sold' style={{ width: this.state.iconHeight, color: this.props.status.sold ? 'springgreen' : 'rgba(0, 0, 0, .15)', filter: this.props.status.sold ? 'drop-shadow(2px 2px 1px rgba(0, 0, 0, .25))' : 'unset' }} onClick={event => this.handleClick(event)}>
                    <FontAwesomeIcon icon={faDollarSign} />
                </IconButton>
                <IconButton name='dead' style={{ width: this.state.iconHeight, color: this.props.status.dead ? 'black' : 'rgba(0, 0, 0, .15)', filter: this.props.status.dead ? 'drop-shadow(2px 2px 1px rgba(0, 0, 0, .25))' : 'unset' }} onClick={event => this.handleClick(event)}>
                    <FontAwesomeIcon icon={faSkull} />
                </IconButton>
                <IconButton name='own' style={{ width: this.state.iconHeight, color: this.props.status.own ? 'white' : 'rgba(0, 0, 0, .15)', filter: this.props.status.own ? 'drop-shadow(2px 2px 1px rgba(0, 0, 0, .25))' : 'unset' }} onClick={event => this.handleClick(event)}>
                    <FontAwesomeIcon icon={faEgg} />
                </IconButton>
                <IconButton name='own' style={{ width: this.state.iconHeight, color: 'ghostwhite', filter: 'drop-shadow(1px 2px 1px rgba(0, 0, 0, .25))' }} onClick={() => this.props.openDateDialog(true)}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </IconButton>
            </Box>
        )
    }
}

class ProfileAppBarButtons extends React.Component {
    render() {
        return (
            <Box className={styles.Shrink} display='flex' justifyContent='flex-end'>
                {this.props.updated ? (
                    <Button color='inherit' component={Link} to='/main'>
                        Ana Menü
                        &nbsp;
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                ):(
                    <ButtonGroup variant='text' color='primary' disableElevation>
                        <Button color='inherit' onClick={this.props.reset}>
                            İptal
                            &nbsp;
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button color='inherit' onClick={this.props.save}>
                            Kaydet
                            &nbsp;
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </ButtonGroup>
                )}
            </Box>
        )
    }
}

class ProfileContent extends React.Component {
    render() {
        return (
            <Grid className={styles.Content} container alignItems='stretch' wrap='wrap'>
                <Grid className={styles.ProfilePictureContainer} item xs={12} sm={6}>
                    <ProfilePicture
                        image={this.props.content.img}
                        notes={this.props.content.notes}
                        updateNotes={this.props.updateFunctions.updateNotes}
                        updateImage={this.props.updateFunctions.updateImage}
                        delete={this.props.updateFunctions.delete}
                    />
                </Grid>
                <Grid className={styles.ParentCardContainer} item xs={12} sm={6} container direction='row' alignItems='stretch'>
                    <Grid item xs={12}>
                        <ParentCard updateParent={this.props.updateFunctions.updateParent} content={this.props.content.father} />
                    </Grid>
                    <Grid item xs={12}>
                        <ParentCard updateParent={this.props.updateFunctions.updateParent} content={this.props.content.mother} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

class ProfilePicture extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notes: "",
            imageOpen: false,
            imageEditorOpen: false,
            notesOpen: false,
            deleteOpen: false
        }

        this.inputRef = React.createRef()
        this.formRef  = React.createRef()
    }

    srcToFile = (src) => {
        const mimeType = src.substring(src.indexOf(":") + 1).split(",")[0].split(";")[0]

        return (
            fetch(src).then((res) => {
                return res.arrayBuffer()
            }).then((buf) => {
                return new File([buf], (Math.random() * 1e12).toFixed(0), { type: mimeType })
            }).catch((err) => {
                console.error(err)
            })
        );
    }

    handleImageSave = (file) => {
        this.setState({ imageOpen: false, imageEditorOpen: false })
        this.props.updateImage(file)
    }

    render() {
        return (
            <Box className={styles.ProfilePicture}>
                <Card className={styles.ProfilePictureCard}>
                    <CardMedia className={styles.ProfilePictureMedia} image={this.props.image || '/fallback.png'} />
                </Card>
                <Box className={styles.ProfilePictureButtons}>
                    <Fab variant='extended' color='primary' onClick={() => this.setState({ imageOpen: true })}>
                        Değiştir
                        &nbsp;
                        <FontAwesomeIcon icon={faPen} />
                    </Fab>
                    <Fab variant='extended' color='default' onClick={() => this.setState({ notesOpen: true })}>
                        Notlar
                        &nbsp;
                        <FontAwesomeIcon icon={faEdit} />
                    </Fab>
                    <Fab variant='extended' color='secondary' onClick={() => this.setState({ deleteOpen: true })}>
                        Sil
                        &nbsp;
                        <FontAwesomeIcon icon={faTrash} />
                    </Fab>
                </Box>
                <Dialog open={this.state.imageOpen} onClose={() => this.setState({ imageOpen: false })}>
                    <DialogTitle>Resim Değişikliği</DialogTitle>
                    <DialogContent>
                        <Typography color='secondary' variany='subtitle1'>UYARI!</Typography>
                        <Typography>Resim değişikliği internet bağlantısı gerektirir.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.setState({ imageOpen: false })}>
                            Vazgeç
                        </Button>
                        <Button color='primary' onClick={() => this.setState({ imageEditorOpen: true })}>
                            Değiştir
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.notesOpen} TransitionProps={{ onEnter: () => this.setState({ notes: this.props.notes })}} onClose={() => this.setState({ notesOpen: false })}>
                    <DialogTitle>Notlar</DialogTitle>
                    <DialogContent>
                        <Box component='form' ref={this.formRef} onSubmit={event => {
                            event.preventDefault()
                            this.props.updateNotes(this.state.notes)
                            this.setState({ notesOpen: false })
                        }}>
                            <TextField label='Notlar' value={this.state.notes} onChange={event => this.setState({ notes: event.target.value })} rowsMax={8} multiline fullWidth autoFocus />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.setState({ notes: this.props.notes, notesOpen: false })}>
                            Kapat
                        </Button>
                        <Button color='primary' onClick={() => this.formRef.current.requestSubmit()}>
                            Kaydet
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.deleteOpen} onClose={() => this.setState({ deleteOpen: false })}>
                    <DialogTitle>Profili Sil</DialogTitle>
                    <DialogContent>
                        <Typography variant='body1'>
                            Profili silmek için onay gerekli.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={() => this.setState({ deleteOpen: false })}>
                            İptal
                            &nbsp;
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button color='secondary' onClick={this.props.delete}>
                            Profili Sil
                            &nbsp;
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <ImageEditor open={this.state.imageEditorOpen} onClose={() => this.setState({ imageEditorOpen: false })} onSave={this.handleImageSave} />
            </Box>
        )
    }
}

class ParentCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            parentSelectorOpen: false
        }
    }

    updateParent = (ring) => {
        this.setState({parentSelectorOpen: false})
        this.props.updateParent(ring)
    }

    render() {
        return (
            <Card className={styles.ParentCard}>
                <CardMedia className={styles.ParentCardMedia} image={this.props.content.img || '/fallback.png'} component={Link} to={'/profile?ring=' + this.props.content.ring} />
                <CardContent className={styles.ParentCardContent}>
                    <Box flexBasis='40%'>
                        <Box display='flex' alignContent='center' justifyContent='space-between'>
                            <Box flexShrink={1} display='flex' alignItems='center'>
                                <Typography variant='h6'>
                                    {this.props.content.male ? 'Baba' : 'Anne'}
                                </Typography>
                            </Box>
                            <Box flexShrink={1}>
                                <IconButton onClick={() => this.setState({ parentSelectorOpen: true })}>
                                    <FontAwesomeIcon icon={faPen} size='sm' />
                                </IconButton>
                            </Box>
                            <ParentSelector open={this.state.parentSelectorOpen} close={() => this.setState({ parentSelectorOpen: false })} updateParent={this.updateParent} male={this.props.content.male} />
                        </Box>
                        <Typography variant='subtitle1'>
                            {this.props.content.ring}
                        </Typography>
                    </Box>
                    <Box className={styles.GrandParentContainer} flexBasis='60%' display='flex'>
                        <Box flexBasis='50%' display='flex' flexDirection='column'>
                            <Typography align='center' variant='subtitle1'>Baba</Typography>
                            <Card className={styles.GrandParent}>
                                <CardMedia image={this.props.content.father.img || '/fallback.png'} component={Link} to={'/profile?ring=' + this.props.content.father.ring} />
                            </Card>
                        </Box>
                        <Box flexBasis='50%' display='flex' flexDirection='column'>
                            <Typography align='center' variant='subtitle1'>Anne</Typography>
                            <Card className={styles.GrandParent}>
                                <CardMedia image={this.props.content.mother.img || '/fallback.png'} component={Link} to={'/profile?ring=' + this.props.content.mother.ring} />
                            </Card>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        )
    }
}

class ParentSelector extends React.Component {
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.close} fullScreen>
                <Main close={this.props.close} gender={this.props.male ? 'male' : 'female'} updateParent={this.props.updateParent} />
            </Dialog>
        )
    }
}

class ImageEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            image: null,
            croppedAreaPixels: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 3 / 4,
        }
    }

    readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    onFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            this.readFile(event.target.files[0]).then((image) => this.setState({ image }))
        }
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels })
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    handleClose = () => {
        this.setState({ image: null })
        this.props.onClose()
    }

    handleSave = () => {
        getCroppedImg(this.state.image, this.state.croppedAreaPixels).then((data) => {
            this.props.onSave(data)
            this.setState({ image: null })
        })
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} fullScreen>
                <DialogContent>
                    {this.state.image ? (
                        <Cropper
                            min={1}
                            max={3}
                            step={0.1}
                            image={this.state.image}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                        />
                    ):(
                        this.props.open ? <FileUpload onFileChange={this.onFileChange} /> : null
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" onClick={this.handleClose}>İptal</Button>
                    <Button variant="contained" color="primary" onClick={this.handleSave}>Kaydet</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const FileUpload = (props) => {
    const [ref, setRef] = useState(null)

    useEffect(() => {
        if (ref) ref.click()
    }, [ref])

    return (
        <input
            ref={(update) => setRef(update)}
                type="file"
                accept="image/*"
                multiple={false}
                onChange={props.onFileChange}
                style={{
                    display: "none"
                }}
            />
    )
} 
