// React Core
import React from 'react'

// React Router
import { Link } from 'react-router-dom'

// Material UI Components
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// FontAwesome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFeatherAlt,
    faSearch,
    faPlus,
    faFilter,
    faVenusMars,
    faStar,
    faSkull,
    faDollarSign,
    faEgg,
    faHandHoldingUsd,
    faCalendarAlt,
    faSignOutAlt,
    faTimes,
    faBars,
    faSave,
    faSyncAlt
} from '@fortawesome/free-solid-svg-icons'

// CSS Modules
import styles from './modules/Main.module.css'

export default class Main extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            birds: [],
            filter: {
                ring: "",
                gender: this.props.gender || 'all',
                year: 'all',
                fav: 'all',
                sold: 'all',
                dead: 'all',
                own: 'all'
            },
            count: 0,
            openFilter: false
        }
    }

    updateBirds = () => {
        const birds = JSON.parse(localStorage.getItem('birds'))
        this.setState({ birds: birds })
    }

    updateFilter = (filter) => {
        console.log(filter)
        console.log(this.state.filter)
        this.setState({ filter: { ...this.state.filter, ...filter } })
    }

    openCloseFilter = (open) => {
        this.setState({ openFilter: open })
    }

    componentDidMount() {
        this.updateBirds()
    }

    filterGrid = () => {
        return this.state.birds.filter(bird => {
            const ring = bird.ring.replaceAll(/[ -./]/g, '').toUpperCase().includes(this.state.filter.ring.toUpperCase())

            const gender = (
                (this.state.filter.gender === 'all') ||
                (this.state.filter.gender === 'male'   ? bird.male === true  : false) ||
                (this.state.filter.gender === 'female' ? bird.male === false : false)
            )

            const fav  = (
                (this.state.filter.fav  === 'all') ||
                (this.state.filter.fav  === 'favorite' ? bird.status.fav === true  : false) ||
                (this.state.filter.fav  === 'regular'  ? bird.status.fav === false : false)
            )

            const sold = (
                (this.state.filter.sold === 'all') ||
                (this.state.filter.sold === 'sold' ? bird.status.sold === true  : false) ||
                (this.state.filter.sold === 'stay' ? bird.status.sold === false : false)
            )

            const dead = (
                (this.state.filter.dead === 'all') ||
                (this.state.filter.dead === 'dead'  ? bird.status.dead === true  : false) ||
                (this.state.filter.dead === 'alive' ? bird.status.dead === false : false)
            )

            const own  = (
                (this.state.filter.own  === 'all') ||
                (this.state.filter.own  === 'own'    ? bird.status.own === true  : false) ||
                (this.state.filter.own  === 'bought' ? bird.status.own === false : false)
            )

            const year = (
                (this.state.filter.year === 'all') ||
                (this.state.filter.year === (new Date(bird.date)).getFullYear())
            )

            return ring && gender && fav && sold && dead && own && year
        })
    }

    render() {
        return (
            <Container component={Paper} className={styles.Container} maxWidth='md' disableGutters>
                <Header
                    close={this.props.close}
                    history={this.props.history}
                    openCloseFilter={this.openCloseFilter}
                    updateBirds={this.updateBirds}
                    updateFilter={this.updateFilter}
                    signOut={this.props.signOut}
                    synchronize={this.props.synchronize}
                    filtered={this.state.count}
                />
                <ImageGrid
                    count={count => this.setState({ count })}
                    updateParent={this.props.updateParent}
                    birds={this.filterGrid()}
                />
                <Filter
                    openCloseFilter={this.openCloseFilter}
                    filter={this.state.filter}
                    open={this.state.openFilter}
                    updateFilter={this.updateFilter}
                />
                <Backdrop className={styles.Backdrop} open={this.props.block}>
                    <CircularProgress color='inherit' />
                </Backdrop>
            </Container>
        )
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ring: "",
            error: false,
            drawerOpen: false,
            syncDialogOpen: false,
            addBirdOpen: false,
            logoutDialogOpen: false
        }

        this.formRef = React.createRef()
    }

    handleSync = (res) => {
        if (res) {
            this.props.updateBirds()
            this.setState({ syncDialogOpen: false })
        } else {
            alert('Senkronizasyon başarısız!')
        }
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const birds = JSON.parse(localStorage.getItem('birds'))
        const index = birds.findIndex(bird => bird.ring === this.state.ring)

        if (index === -1) {
            birds.push({
                ring: this.state.ring,
                male: false,
                date: (new Date()).toISOString(),
                status: {
                    fav: false,
                    sold: false,
                    dead: false,
                    own: true
                }
            })

            localStorage.setItem('birds', JSON.stringify(birds))
            localStorage.setItem('sync', (new Date()).toISOString())

            this.props.history.push('/profile?ring=' + this.state.ring)
        } else {
            this.setState({ error: true })
        }
    }

    render() {
        return (
            <AppBar className={styles.AppBar} position='relative' elevation={0}>
                <Box className={styles.Wrapper}>
                    <Box className={styles.Shrink}>
                        <Typography variant='h5' noWrap>
                            <FontAwesomeIcon icon={faFeatherAlt} size='lg' />
                            &nbsp;
                            Budgie
                        </Typography>
                    </Box>
                    <Box className={styles.Grow}>
                        <InputBase
                            className={styles.Search}
                            placeholder='Bilezik Numarası'
                            onChange={event => this.props.updateFilter({ ring: event.target.value.replaceAll(/[ -./]/g, '') })}
                            startAdornment={<FontAwesomeIcon icon={faSearch} />}
                            endAdornment={this.props.filtered}
                            fullWidth
                        />
                        <Button
                            className={styles.Button}
                            color='inherit'
                            onClick={event => this.props.openCloseFilter(true)}
                        >
                            <FontAwesomeIcon icon={faFilter} />
                            &nbsp;
                            Filtre
                        </Button>
                    </Box>
                    <Box className={styles.Shrink}>
                        {this.props.updateParent ? (
                            <IconButton color='inherit' onClick={this.props.updateParent}>
                                <FontAwesomeIcon icon={faSave} size='sm' />
                            </IconButton>
                        ) : (
                                undefined
                            )}
                        <IconButton color='inherit' onClick={this.props.close ? (this.props.close) : (event => this.setState({ drawerOpen: true }))}>
                            <FontAwesomeIcon icon={this.props.close ? faTimes : faBars} size='sm' />
                        </IconButton>
                    </Box>
                </Box>
                <Drawer anchor='right' open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: false })}>
                    <List component='nav'>
                        <ListItem button onClick={() => this.setState({ syncDialogOpen: true })}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </ListItemIcon>
                            <ListItemText>
                                Senkronize
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => this.setState({ addBirdOpen: true })}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faPlus} />
                            </ListItemIcon>
                            <ListItemText>
                                Ekle
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => this.setState({ logoutDialogOpen: true })}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </ListItemIcon>
                            <ListItemText>
                                Çıkış
                            </ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
                <Dialog open={this.state.syncDialogOpen} onClose={event => this.setState({ syncDialogOpen: false })}>
                    <DialogTitle>Senkronize Et</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Cihazdaki verileri internetteki verilerle eşler.
                            <br />
                            <br />
                            <Typography variant='subtitle1' color='secondary'>UYARI!</Typography>
                            Eğer internetteki veriler cihazdakilerden daha yeniyse verileri silip internetten yükler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={event => this.setState({ syncDialogOpen: false })} color="primary">
                            Vazgeç
                        </Button>
                        <Button onClick={() => this.props.synchronize(this.handleSync)} color="primary" autoFocus>
                            Senkronize Et
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.addBirdOpen} onClose={event => this.setState({ addBirdOpen: false })}>
                    <DialogTitle>Yeni Kuş Ekle</DialogTitle>
                    <DialogContent>
                        <Box component='form' ref={this.formRef} onSubmit={this.handleSubmit}>
                            <TextField label='Bilezik Numarası' value={this.state.ring} onChange={event => this.setState({ ring: event.target.value })} error={this.state.error} autoFocus required />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ addBirdOpen: false })} color="primary">
                            İptal
                        </Button>
                        <Button onClick={() => this.formRef.current.requestSubmit()} color="primary">
                            Ekle
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.logoutDialogOpen} onClose={event => this.setState({ logoutDialogOpen: false })}>
                    <DialogTitle>Çıkış Yap</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Çıkış yapılabilmesi için bilgilerin senkronize edilmesi gereliyor.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={event => this.setState({ logoutDialogOpen: false })} color="primary">
                            İşlemi İptal Et
                        </Button>
                        <Button onClick={this.props.signOut} color="primary" autoFocus>
                            Senkronize Et ve Çıkış Yap
                        </Button>
                    </DialogActions>
                </Dialog>
            </AppBar>
        )
    }
}

class ImageGrid extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            gridRootWidth: 600,
            count: 0
        }

        this.styles = {
            Backdrop: {
                width: '100%',
                height: '100%',
                color: '#f8f8ff'
            },
            List: {
                padding: '.25rem'
            },
            ListItem: {
                padding: '.25rem',
                cursor: 'pointer'
            }
        }

        this.gridRoot = React.createRef()
    }

    componentDidMount() {
        this.setState({ gridRootWidth: this.gridRoot.current.offsetWidth })
    }

    componentDidUpdate() {
        if (this.state.count !== this.props.birds.length) this.setState({ count: this.props.birds.length }, () => this.props.count(this.props.birds.length))
    }

    render() {
        return (
            <ImageList style={this.styles.List} gap={0} cols={this.state.gridRootWidth < 600 ? 2 : 5} component='div' ref={this.gridRoot}>
                {this.props.birds ? (
                    this.props.birds.reverse().map((item, index) => (
                        <ImageListItem
                            style={this.styles.ListItem}
                            key={index}
                            component={this.props.updateParent ? 'a' : Link}
                            to={this.props.updateParent ? undefined : '/profile?ring=' + item.ring}
                            onClick={this.props.updateParent ? () => this.props.updateParent(item.ring) : undefined}
                        >
                            <img alt={item.ring} src={item.img || '/logo192.png'} />
                            <CustomTileBar item={item} />
                            <ColorBar year={(new Date(item.date)).getFullYear()} />
                        </ImageListItem>
                    ))
                ) : (
                        <Backdrop open={true} style={this.styles.Backdrop}>
                            <CircularProgress color='inherit' />
                        </Backdrop>
                    )}
            </ImageList>
        )
    }
}

function CustomTileBar(props) {
    return (
        <ImageListItemBar title={props.item.ring} actionIcon={(
            <IconButton className={styles.IconButton}>
                {
                    props.item.status === undefined ? (
                        <FontAwesomeIcon icon={faFeatherAlt} />
                    ):(
                        props.item.status.fav ? (
                            <FontAwesomeIcon icon={faStar} />
                        ):(
                            props.item.status.dead ? (
                                <FontAwesomeIcon icon={faSkull} />
                            ):(
                                props.item.status.sold ? (
                                    <FontAwesomeIcon icon={faDollarSign} />
                                ):(
                                    props.item.status.own ? (
                                        <FontAwesomeIcon icon={faEgg} />
                                    ):(
                                        <FontAwesomeIcon icon={faHandHoldingUsd} />
                                    )
                                )
                            )
                        )
                    )
                }
            </IconButton>
        )}/>
    )
}

class ColorBar extends React.Component {
    constructor(props) {
        super(props)

        this.pivotYear = 2016
        this.colorMap = [
            '#603000', // 2016 - pivot
            '#6495ED',
            '#DC143C',
            '#010202',
            '#008000',
            '#800080',
        ]
    }

    getYearColor(year) {
        return this.colorMap[(year - this.pivotYear) % this.colorMap.length]
    }

    render() {
        return (
            <span className={styles.ColorBar} style={{ backgroundColor: this.getYearColor(this.props.year) || '#ff00ff' }}></span>
        )
    }
}

class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: JSON.parse(JSON.stringify(this.props.filter)),
            openStartDate: false,
            openEndDate: false
        }
    }

    handleChange = (event) => {
        const update = JSON.parse(JSON.stringify(this.state.filter))
        update.ring  = this.props.filter.ring
        update[event.target.name] = event.target.value
        this.setState({ filter: update })
    }

    handleDateChange = (event) => {
        const update = JSON.parse(JSON.stringify(this.state.filter))
        update.ring  = this.props.filter.ring
        update.year  = typeof event.target.value === 'string' ? 'all' : event.target.value
        this.setState({ filter: update })
    }

    handleReset = () => {
        const update = JSON.parse(JSON.stringify(this.state.filter))
        for (const key in update) {
            if (update.hasOwnProperty(key)) {
                if (key !== 'ring') update[key] = 'all'
            }
        }
        this.setState({ filter: update })
    }

    handleCancel = () => {
        this.setState({ filter: {...this.props.filter} })
        this.props.openCloseFilter(false)
    }

    handleOk = () => {
        this.props.updateFilter(this.state.filter)
        this.props.openCloseFilter(false)
    }

    getDateList = () => {
        const dates = ['Hepsi']
        for (let i = 2017; i <= (new Date()).getFullYear() + 1; i++) dates.push(i)
        return dates
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleCancel} maxWidth='sm' fullWidth>
                <DialogTitle>Filtreler</DialogTitle>
                <DialogContent>
                    <Box className={styles.FilterGrid}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Cinsiyet
                                <FontAwesomeIcon icon={faVenusMars} />
                            </FormLabel>
                            <Select name='gender' value={this.state.filter.gender} onChange={this.handleChange}>
                                <MenuItem value='all'>Hepsi</MenuItem>
                                <MenuItem value='male'>Erkek</MenuItem>
                                <MenuItem value='female'>Dişi</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Favori
                                <FontAwesomeIcon icon={faStar} />
                            </FormLabel>
                            <Select name='fav' value={this.state.filter.fav} onChange={this.handleChange}>
                                <MenuItem value='all'>Hepsi</MenuItem>
                                <MenuItem value='favorite'>Favori</MenuItem>
                                <MenuItem value='regular'>Diğer</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Satış durumu
                                <FontAwesomeIcon icon={faDollarSign} />
                            </FormLabel>
                            <Select name='sold' value={this.state.filter.sold} onChange={this.handleChange}>
                                <MenuItem value='all'>Hepsi</MenuItem>
                                <MenuItem value='sold'>Satılmış</MenuItem>
                                <MenuItem value='stay'>Satılmamış</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Canlı kuş
                                <FontAwesomeIcon icon={faSkull} />
                            </FormLabel>
                            <Select name='dead' value={this.state.filter.dead} onChange={this.handleChange}>
                                <MenuItem value='all'>Hepsi</MenuItem>
                                <MenuItem value='alive'>Canlı</MenuItem>
                                <MenuItem value='dead'>Ölü</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Satın alınma
                                <FontAwesomeIcon icon={faEgg} />
                            </FormLabel>
                            <Select name='own' value={this.state.filter.own} onChange={this.handleChange}>
                                <MenuItem value='all'>Hepsi</MenuItem>
                                <MenuItem value='own'>Kendi Kuşum</MenuItem>
                                <MenuItem value='bought'>Satın Alınmış</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Yıl
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </FormLabel>
                            <Select
                                value={typeof this.state.filter.year === 'string' ? 'Hepsi' : this.state.filter.year}
                                onChange={this.handleDateChange}
                            >
                                {this.getDateList().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleReset}  color="primary">Sıfırla</Button>
                    <Button onClick={this.handleCancel} color="primary">Kapat</Button>
                    <Button onClick={this.handleOk}     color="primary">Tamam</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
